<template>
  <div class="login">
    <div class="login-bg">
      <img src="@/assets/login_bg@2x.png" alt="" />
    </div>
    <div class="login-content">
      <div class="login-from">
        <div class="login-from-img">
          <img class="login-from-img-src" src="@/assets/logo@2x.png" />
        </div>
        <div class="login-from-input">
          <van-cell-group>
            <van-field type="tel" v-model="formInfo.mobilePhone" :disabled="disabled" placeholder="请输入手机号" />
            <van-field
              type="number"
              v-model="formInfo.authCode"
              center
              clearable
              label=""
              placeholder="请输入短信验证码"
            >
              <van-button
                @click="getMessCode"
                slot="button"
                size="small"
                type="primary"
                >{{ sendBtn }}</van-button
              >
            </van-field>
          </van-cell-group>
          <div class="login-btn" @click="goLogin">
            <van-button type="info">登录</van-button>
          </div>
        </div>
      </div>
      <van-popup v-model="show">
        <div class="login-popup" @click="hidePopup">
          <div class="login-popup-img">
            <img class="login-popup-img" src="@/assets/tip.png" alt="" />
          </div>
          <div class="login-popup-text">
            <p>{{ text }}</p>
          </div>
          <div class="login-popup-btn" @click="hidePopup">
            <span class="login-popup-btn-span">确定</span>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getCurUserInfo, sendAuthCodeByMobile, loginByMobileAndAuthCode, loginBySignerIdAndTenant, signatureDetails,getProcessInfoByLinkCode,getCertificationInfo,getApplyAuthorityResult } from '@/util/api'
import { mapMutations, mapState } from 'vuex'
import { changeFormData } from "@/util/tool";
import { Base64 } from 'js-base64'

export default {
  data() {
    return {
      show: false,
      formInfo: {
        mobilePhone: '',
        authCode: "",
      },
      sendBtn: "发送验证码",
      sendTime: 60,
      text: "您有1份电子文件待签署，电子文件与纸质文件具有同等法律效力，请务必仔细阅读和理解文件内容。请警惕任何以兼职刷单返现、高额返利、垫付手续费、缴纳保证金等理由的交易骗局。切勿轻信任何来历不明的并收取高额佣金的维权平台。",
      disabled: false
    };
  },
  computed: {
    ...mapState(['linkCodeInfo', 'userInfo', 'accesstoken', 'tenantId'])
  },
  created () {
    console.log(this.$route.query)
    const param = this.$route.query
    console.log(Base64.decode(param.params))
    console.log('created');
    const {linkCode,linkType,loginName,tenantId } = this.$route.query
    if (this.accesstoken && linkType && linkCode) {
      getCurUserInfo().then(async res => {
        //let { tenantId, contact } = res
        let newTenantId = res.tenantId
        let newLoginName = res.contact
        if(linkType == 1 && linkCode){
          await this.getProcessInfoByLinkCode()
        }
        if(newTenantId == tenantId&&newLoginName == loginName) {
              this.setUserInfo(res)
              await this.linkToStatus()
              return ;
        }
        //this.goPage()
        /***
        if (this.userInfo.tenantId == tenantId&&this.formInfo.mobilePhone == contact) {
          // this.goPage()
          if(this.$route.query.linkCode && (!this.$route.query.linkType || this.$route.query.linkType == 1) ){
            await this.getProcessInfoByLinkCode()
            await this.linkToStatus()
          }else{
            this.goPage()
          }
        }
        ***/
      })
    }
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setSignatureId', 'setLinkCodeInfo', 'setAllSignData', 'setPosiInfo','setResultStatus','removeAllState']),
    linkTo() {
      this.$router.push({ path: "/login" });
    },
    hidePopup() {
      this.show = false;
    },
    getMessCode() {
      const { mobilePhone } = this.formInfo
      if (mobilePhone) {
        if (this.sendTime < 60) {
          return false
        }
        let from = { captcha: '', mobilePhone }
        if (this.tenantId) {
          from = { captcha: '', mobilePhone, tenantId: this.tenantId }
        }
        sendAuthCodeByMobile(from).then((res) => {
          console.log('res', res);
          const timer = setInterval(() => {
            this.sendTime -= 1;
            this.sendBtn = `${this.sendTime}s后重试`;
            if (this.sendTime === 0) {
              this.sendBtn = "发送验证码";
              this.sendTime = 60;
              clearInterval(timer);
            }
          }, 1000);
        })
      } else {
        this.$toast.fail("请输入用户名");
      }
    },
    async goLogin () {
      console.log('goLogin');
      this.setPosiInfo([])
      this.setAllSignData([])
      const { linkType,linkCode } = this.$route.query
      const { authCode, mobilePhone } = this.formInfo

      console.log('linkType', linkType);
      console.log('linkCode', linkCode);
      if(!authCode){
        this.$toast.fail('验证码不能为为空')
        return
      }
      loginByMobileAndAuthCode({ authCode, mobilePhone, tenantId: this.$route.query.tenantId || this.tenantId }).then(async (res) => {
        // this.setUserInfo(res)
        // eslint-disable-next-line no-debugger
        if(!res){
          this.sendTime = 60
          return
        }

        if(linkType == 1 && linkCode){
          await this.getProcessInfoByLinkCode()
        }
        if (Array.isArray(res)) {
          const tenantId = res[0].tenantId
          const { signerId } = res.find((v) => v.tenantId === tenantId) || {}
          if (!signerId) {
            this.$toast.fail("未找到租户！");
            return
          }
          loginBySignerIdAndTenant({ signerId, tenantId }).then(async (res) => {
            this.setUserInfo(res)
            this.setSignatureId(signerId)
            await this.linkToStatus()
            //this.getProcessInfoByLinkCode()
            //this.goPage()
          })
        } else {
          this.setUserInfo(res)
          await this.linkToStatus()
          //if(this.$route.query.linkCode && (!this.$route.query.linkType || this.$route.query.linkType == 1) ){
          //if(linkType == 1){
          //  await this.linkToStatus()
         // }else{
          //  this.goPage()
          //}
        }
      })
    },
    linkToStatus() {
      console.log('linkToStatus');
      const { linkType,linkCode } = this.$route.query
      if(!linkType || !linkCode) {
        // this.getSignatureToSignList();
        this.$router.push({path: '/usePage',query:{}})
        return ;
      }
      const { jyqUserId } = this.userInfo
      if(linkType == 1 && linkCode){
        const { signatureId } = this.linkCodeInfo
        const params = changeFormData({ signatureId })
        signatureDetails(params).then(res => {  
             const { initiatorName, initiatorUserId } = res || {}
             if(this.linkCodeInfo.signStatus == 5 && initiatorName && initiatorUserId !== jyqUserId){
                 this.$router.push({ path: '/withdraw', query: { initiatorName } })
                 return
             }
            this.$router.push({path: '/home'})
        })
      }
      //授权认证
      if((linkType == 2) && linkCode){
          this.$router.push({path: '/licenseAgreement', query:{linkCode}})
      }
      //企业实名认证 3:通过；31:不通过
      if((linkType == 3 || linkType == 31) && linkCode){
          this.getCertificationInfo()
      }
      //签署权限
      if(linkType == 4 && linkCode) {
          this.getApplyAuthorityResult()
      }
      //个人实名认证
      if((linkType == 5) && linkCode){
          this.$router.push({path: '/faceCenterPage', query:{linkCode}})
      }
      //批量签署
      if(linkType == 0 && linkCode){
          this.$router.push({path: '/usePage', query:{linkCode}})
      }
    },
    goPage () {
      console.log('getCertificationInfo1');
      const { linkType,linkCode } = this.$route.query
      if(!linkType || !linkCode) {
        // this.getSignatureToSignList();
        this.$router.push({path: '/usePage',query:{}})
        return ;
      }
      const { jyqUserId } = this.userInfo
      if(linkType == 1 && linkCode){
        const { signatureId } = this.linkCodeInfo
        const params = changeFormData({ signatureId })
        signatureDetails(params).then(res => {  
             const { initiatorName, initiatorUserId } = res || {}
             if(this.linkCodeInfo.signStatus == 5 && initiatorName && initiatorUserId !== jyqUserId){
                 this.$router.push({ path: '/withdraw', query: { initiatorName } })
                 return
             }
            this.$router.push({path: '/home'})
        })
      }
      if(linkType == 2 && linkCode){
          this.$router.push({path: '/licenseAgreement', query:{linkCode}})
      }
      if((linkType == 3 || linkType == 31) && linkCode){
          this.getCertificationInfo()
      }
      if(linkType == 4 && linkCode) {
          this.getApplyAuthorityResult()
      }
      if(linkType == 5 && linkCode){
          this.$router.push({path: '/faceCenterPage', query:{linkCode}})
      }

      if(linkType == 0 && linkCode){
          this.$router.push({path: '/usePage', query:{linkCode}})
      }
    },
    getApplyAuthorityResult () {
      const {linkCode} = this.$route.query
      getApplyAuthorityResult(linkCode).then(res =>{
        this.$store.commit('setResultStatus',res)
        this.$router.push({path: '/authenticationPage'})
      })
    },
    getCertificationInfo () {
      console.log('getCertificationInfo2');
      const {linkCode} = this.$route.query
      getCertificationInfo(linkCode).then(res =>{
        console.log(res);
        if(res.approvalResult == 3){
          if(res.companyId){
            this.$router.push({path:'/enterpriseCertification',query:{companyId:res.companyId}})
          }
        }else{
          this.$store.commit('setResultInfo',res)
          this.$router.push({path: '/authenticationPage'})
        }
      })
    },
    async getProcessInfoByLinkCode () {
      const { linkCode } = this.$route.query
      const formData = new FormData()
      formData.append('linkCode', linkCode)
      await getProcessInfoByLinkCode(formData).then((res) => {
        console.log('res', res);
        // this.setLinkCodeInfo(res)
        if(res){
          this.$store.commit('setLinkCodeInfo',res)
          this.$store.commit('setUserInfo',res)
        }
      })
    },
    // async getSignatureToSignList () {
    //     getSignatureToSignList().then(res =>{
    //         if(res){
    //             this.$store.commit('setSignList',res)
    //         }
    //     })
    // }
  },
  mounted() {
    // console.log(this.$route.query);
    // const { signerContact = '' } = this.$route.query || {}
    // if (signerContact) {
    //   this.setLinkCodeInfo(this.$route.query)
    // }
    if (this.$route.query.signActionStatus == 1) {
      this.show = true
    }
    this.formInfo.mobilePhone = this.$route.query.loginName ? this.$route.query.loginName : ''
    if (this.formInfo.mobilePhone) this.disabled = true
  }
};
</script>

<style lang="less" scoped>
/deep/.van-field__control {
    color: #464646 !important;
  }
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &-bg {
    width: 100%;
    height: 266px;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  }
  &-content {
    flex: 1;
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
    width: 100%;
    margin-top: -40px;
    background: #fff;
  }
  &-from {
    // width: 300px;
    &-title {
      font-size: 40px;
      color: #999;
      text-align: center;
    }
    &-img {
      width: 113px;
      margin: 37px 0 0 30px;
      &-src {
        width: 100%;
        height: 100%;
      }
    }
    &-input {
      width: 317px;
      margin: 0 auto;
    }
    // /deep/ .van-field__button {
    //   width: 85px;
    // }
  }
  &-btn {
    margin-top: 48px;
    
    // /deep/ .van-button--info {
    //   width: 100%;
    //   border-radius: 25px;
    //   background: linear-gradient(180deg, #4799F9 0%, #1175EB 100%);
    // }
  }
  &-popup {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 0 0;
    &-img {
      width: 198px;
    }
    &-text {
      width: 272px;
      min-height: 134px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #464646;
      opacity: 1;
      text-align: center;
      margin: 16px;
    }
    &-btn {
      width: 300px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1Px solid #EEEEEE;
      &-span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0067e1;
        opacity: 1;
      }
    }
  }

}
</style>